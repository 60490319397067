<template>
  <FullPopup cols="col-11 col-md-8 col-lg-6 col-xxl-6" >
    <ContentWithBackgroundAndFooter>
      <MobileBackButton btnText="Back to Recurring Donations" @close="$emit('closeEdit')" />
      <div class="row my-3">
        <div class="col-12 text-end cursor d-none d-md-block" @click="$emit('closeEdit')">
          <ios-close-icon class="inline-icon green-text-dark" />
        </div>
        <div class="col-md-10 offset-md-1 pt-2">
          <div class="row justify-content-center">
            <div class="col-auto text-center p-0 card_title">
              UPDATE BANK DETAILS
              <div class="row m-0 justify-content-center" >
                <div class="col-12 text-center pt-3 border_bottom">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- {{ details }} -->
      <!-- {{ item }} -->
      <div class="row m-0 justify-content-center" v-if="details">
        <div class="col-md-11 px-0 my-3 card_item_inner">
          <div class="row mx-0 h-100 justify-content-center">
            <div class="col-12 p-0">
              <div class="row" v-if="details.donorBankAccount">
                <div class="col-md-6">
                  <div class="row mx-0">
                    <div class="col-12 p-0 mb-1 medium font09">
                      Bank:
                    </div>
                    <div class="col-12 p-0 mb-3">
                      <SelectAll
                        v-model="selectedBank"
                        id="debitMethod"
                        :options="bankOptions"
                        placeholder="Bank"
                        displayValue="bankName"
                        color="gold"
                        :hasMore="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <TextField type="text" color="gold" placeholder="Name" label="Account Holder Name:" v-model="details.donorBankAccount.accountHolderName" id="name" v-if="details.donorBankAccount.accountHolderName" />
                </div>
                <div class="col-6">
                  <TextField type="text" color="gold" placeholder="Account Number" label="Account Number:" v-model="details.donorBankAccount.accountNumber" id="number" v-if="details.donorBankAccount.accountNumber"/>
                </div>
                <div class="col-6">
                  <TextField type="text" color="gold" placeholder="Branch code" label="Branch Code:" v-model="details.donorBankAccount.branchCode" id="branch"  v-if="details.donorBankAccount.branchCode"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentWithBackgroundAndFooter>
    <!--BUTTONS-->
    <div class="col-12 px-4 align-self-end background-green">
      <div class="row mx-0 py-3 align-items-center justify-content-center">
        <div class="col-8 col-md-5 px-2 mb-2 mb-md-0" >
          <Button color="red" size="" width="100" btnText="Cancel Changes" icon="arrow" @buttonClicked="cancelEdit">
            <ios-close-circle-outline-icon class="inline-icon" />
          </Button>
        </div>
        <div class="col-8 col-md-5 px-2" >
          <Button color="green_light" size="" width="100" btnText="Update Bank Details" icon="arrow" @buttonClicked="save" :isLoading="isLoading">
            <IconArrowForward size="size16" />
          </Button>
        </div>
      </div>
    </div>
    <!--END BUTTONS-->
  </FullPopup>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import MobileBackButton from '../../components/MobileBackButton.vue'
import ContentWithBackgroundAndFooter from '@/components/styling/ContentWithBackgroundAndFooter.vue'
import FullPopup from '@/components/styling/FullPopup.vue'

export default {
  components: {
    MobileBackButton,
    ContentWithBackgroundAndFooter,
    FullPopup,
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    'ios-close-circle-outline-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close-circle-outline.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'DebitOrderEditBankPopup',
  props: ['item'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      details: {},
      selectedBank: null,
      isLoading: false,
      checkCurrent: false
    }
  },
  async mounted () {
    this.checkCurrent = false
    this.details = { ...this.item }
    await this.fetchBankOptions()
    await this.currentBank()
  },
  computed: {
    ...mapGetters([
      'bankOptions'
    ])
  },
  watch: {
    selectedBank () {
      if (Object.keys(this.selectedBank).length > 0 && this.checkCurrent) {
        this.details.donorBankAccount.bankName = this.selectedBank.bankName
        if (this.details.donorBankAccount.bankBranch) {
          this.details.donorBankAccount.bankBranch.bankBranchID = this.selectedBank.bankID
        }
      }
    },
    details: {
      deep: true,
      handler (val) {
        this.setBranchCode()
      }
    }
  },
  methods: {
    ...mapActions(['updateDebitOrder', 'fetchBankOptions']),
    async save () {
      this.isLoading = true
      await this.updateDebitOrder(this.details)
      this.isLoading = false
      await this.cancelEdit()
    },
    setBranchCode () {
      if (this.details.donorBankAccount.bankBranch) {
        this.details.donorBankAccount.bankBranch.code = this.details.donorBankAccount.branchCode
      }
    },
    cancelEdit () {
      this.$emit('close')
    },
    currentBank () {
      if (this.bankOptions && this.bankOptions.length > 0) {
        var theBank = this.bankOptions.find(bank => this.details.donorBankAccount.bankName === bank.bankName)
        this.selectedBank = theBank
        this.checkCurrent = true
      }
    }
  }
}
</script>
<style scoped>
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}

</style>
